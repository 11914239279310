
import Common from './common/common';

////

const Init = () => {
	Common();

}

Init();
 

window.$ = window.jQuery = require('jquery');
import mgnSmoothScroll from './lib/mgn-smooth-scroll';
import mgnModal from "./lib/mgn-modal"

const Init = () => {
    // console.log("全ページ共通で利用する機能はこちらに記述してください。");
    // Form();

    var scroll = new mgnSmoothScroll(
        "a",
        {
            speed: 800,
            easing: "easeOutQuint",
            posFix: 0,
            ignore: ".ignore",
            blank: true,
            customAnchor: "?anchor=",
        }
    );

    $(function() {

        //  Set function of header menu;

        $(".o-header_navbtn a").on("click", function() {
            $(".o-header_navbtn").toggleClass("active");
            $(".o-header_navbox").toggleClass("active");
        });

        //  Activate local navigation.

        if ($(".m-localnav").length > 0) {
            var myURL = location.pathname;
            myURL = myURL.slice(0, myURL.lastIndexOf("/") + 1);
            myURL = myURL.replace("view/","");
            for (var i = 0; i < $(".m-localnav a").length; i++) {
                
                if (myURL == $(".m-localnav a").eq(i).attr("href")) {
                    $(".m-localnav a").eq(i).addClass("active");
                }
            }
        }

    })
  
    let modal = new mgnModal(".j-modal");
      modal.OpenEnd = function( index ){
	    console.log(index, "openEnd")
      };
      modal.CloseEnd = function(){
        console.log("closeEnd")
      };
  
}


$(function(){
  $('.p-kv_img ul li:gt(0)').hide();
  var interval = 6000,
      anim = (function () {
      var i = 0,
          timing = interval / 1000 * 60;
      return function () {
          if (i % timing === 0) {
              $('.p-kv_img ul li:first-child').fadeOut(2000).next('li').fadeIn(2000).end().appendTo('.p-kv_img ul');
          }
          i++;
      };
  })();
  (function animloop() {
      anim();
      window.requestAnimationFrame(animloop);
  })();
});


export default Init;
